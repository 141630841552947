body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar */

.navbar {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
  padding: 20px 0;
  background-color: #f8f9fa;
  transition: all 0.3 ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

}

.navbar.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  font-weight: 700;
  font-size: 1.5rem;
  color: #007bff;
}

.navbar-nav .nav-link {
  font-weight: 400;
  font-size: 1.2rem;
  color: #333;
  margin-left: 30px;
}

.navbar-nav .nav-link:hover {
  color: #007bff;
}

.gautam {
  color: #007bff;
}

.container {
  max-width: 1200px;
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 991px) {
  .navbar-nav .nav-link {
    margin-left: 0;
  }
}

/* About */

.about-section {
  background-color: #f8f9fa;
}

.about-section h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.about-section .lead {
  font-family: 'Lora', serif;
  font-size: 1.25rem;
  color: #6c757d;
}

.about-text {
  font-size: 1.2rem; /* Larger text size */
  line-height: 1.6; /* Better line spacing for readability */
  color: #333; /* Darker text for better contrast */
  font-family: 'Lora', serif; /* Use a serif font for a clean and professional look */
  margin-bottom: 1.5rem; /* Space between paragraphs */
  text-align: justify; /* Justify text for cleaner look */
}

.btn-group .btn {
  margin-right: 10px;
  border-radius: 50px;
}

.smaller-profile-pic {
  width: 250px; /* Adjust this size as needed */
  height: 250px;
}

.highlight {
  color: #007bff; /* This matches the color of your "Om Gautam" heading */
}

/* Projects */

.projects-section {
  background-color: #f8f9fa;
  padding: 2rem 0;
}

.projects-section h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
}

.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #007bff; /* Same color as your heading */
}

.card-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

.project-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
}

.project-tag {
  background-color: #e9ecef;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #495057;
  margin-right: 5px;
  margin-bottom: 5px;
}

.github-icon {
  color: #333;
}

.github-icon:hover {
  color: #007bff;
}

.card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 180px;
  object-fit: cover;
}

/* Experience */

.experience-section {
  background-color: #f8f9fa;
  padding: 2rem 0;
}

.experience-section h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 3rem;
}

.timeline {
  position: relative;
  padding: 2rem 0;
  border-left: 6px solid #007bff; /* Thicker timeline */
  margin-left: 40px; /* Adjusted margin for a balanced look */
}

.timeline-item {
  margin-bottom: 2rem;
  position: relative;
}

.timeline-item .timeline-dot {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-radius: 50%;
  border: 3px solid #fff; /* White border for a cleaner look */
  position: absolute;
  left: -3px; /* Center dot on the timeline */
  top: 0; /* Position dot vertically on the line */
  transform: translateX(-50%); /* Center the dot horizontally */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

.timeline-item .timeline-date {
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 1rem;
  position: absolute;
  left: -160px; /* Move date further left to balance the design */
  top: 0;
  width: 120px;
  text-align: right;
  font-style: italic;
}

.timeline-item .timeline-content {
  margin-left: 50px; /* Adjusted for a clean look with the thicker timeline */
  padding: 0;
}

.experience-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #007bff;
}

.experience-subtitle {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.timeline-item p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

.skills-used {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.skill-tag {
  background-color: #e9ecef;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #495057;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* Skills */

.skills-section {
  background-color: #f8f9fa;
  padding: 2rem 0;
}

.skills-section h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 3rem;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Responsive grid with flexible card sizes */
  gap: 20px; /* Space between the cards */
  justify-items: center; /* Center cards within the grid */
}

.skill-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 150px; /* Fixed width for uniformity */
  height: 150px; /* Fixed height for symmetrical layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skill-card:hover {
  transform: translateY(-5px); /* Hover effect */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.skill-icon {
  margin-bottom: 10px;
  color: #007bff; /* Brand color for icons */
}

.skill-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.certifications-list {
  margin-top: 30px;
  font-size: 1rem;
  line-height: 1.5;
}

.certification-item {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.certification-item strong {
  font-weight: 700;
  color: #333;
}

.certification-item em {
  font-weight: 300;
  color: #666;
}

/* Footer */

.footer-section {
  background-color: #333;
  color: #fff;
  padding: 2rem 0;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  margin-bottom: 1rem;
}

.footer-link {
  color: #fff;
  margin: 0 15px;
  font-size: 1.2rem;
  text-decoration: none;
}

.footer-link:hover {
  color: #007bff; /* Matches your brand color */
}

.footer-social {
  margin-bottom: 1rem;
}

.social-icon {
  color: #fff;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #007bff; /* Matches your brand color */
}

.footer-text {
  margin-top: 1rem;
  font-size: 1rem;
  color: #bbb;
}

/* General Styles for Mobile (Max Width 768px) */
@media (max-width: 768px) {

  /* Body */
  body {
    padding-top: 60px; /* Keep padding consistent */
    font-size: 0.9rem; /* Slightly smaller font for mobile */
  }

  /* Navbar */
  .navbar {
    padding: 10px 20px;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;
  }

  .navbar-nav .nav-link {
    margin-left: 0; /* Remove margin between nav links */
    padding: 10px 0;
    font-size: 1.1rem;
  }

  .navbar-brand {
    font-size: 1.2rem; /* Slightly smaller logo font for mobile */
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* About Section */
  .about-section .lead {
    font-size: 1.1rem; /* Smaller text size for readability */
  }

  .about-text {
    line-height: 1.4; /* Reduce line spacing for better readability */
  }

  .smaller-profile-pic {
    width: 200px; /* Smaller profile image */
    height: 200px;
  }

  .btn-group .btn {
    border-radius: 50px;
  }

  /* Projects */
  .projects-section h2 {
    font-size: 2rem; /* Smaller heading */
  }

  .card {
    width: 100%; /* Full width on mobile */
    margin-bottom: 20px; /* Add spacing between cards */
  }

  /* Experience */
  .experience-section h2 {
    font-size: 2rem; /* Smaller heading */
  }

  .timeline {
    margin-left: 20px; /* Adjust margin for mobile */
    padding: 1rem 0;
  }

  .timeline-item .timeline-content {
    margin-left: 20px; /* Adjust margin for mobile */
  }

  .timeline-item .timeline-date {
    left: -100px; /* Adjust date position for mobile */
    width: 80px; /* Make the date width smaller */
    font-size: 0.8rem; /* Reduce font size for the dates */
  }

  /* Skills */
  .skills-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row for mobile */
    gap: 10px; /* Reduce space between cards */
  }

  .skill-card {
    width: 100%; /* Make skill cards full-width on mobile */
    height: auto; /* Adjust height */
  }

  /* Footer */
  .footer-section {
    padding: 1rem 0;
  }

  .footer-link {
    font-size: 1rem; /* Adjust footer link size */
  }

  .footer-text {
    font-size: 0.9rem; /* Smaller footer text */
  }
}

/* Small Mobile (Max Width 576px) */
@media (max-width: 576px) {

  /* Navbar */
  .navbar-nav {
    font-size: 0.9rem; /* Smaller nav items */
  }

  .navbar-brand {
    font-size: 1rem; /* Smaller logo */
  }

  /* About Section */
  .about-section .lead {
    font-size: 1rem; /* Reduce lead text */
  }

  .about-text {
    line-height: 1.3; /* Further reduce line spacing */
  }

  .smaller-profile-pic {
    width: 150px; /* Further reduce profile image size */
    height: 150px;
  }

  .btn-group .btn {
    border-radius: 50px;
  }

  /* Projects */
  .projects-section h2 {
    font-size: 1.8rem; /* Smaller project heading */
  }

  /* Experience */
  .experience-section h2 {
    font-size: 1.8rem; /* Smaller experience heading */
  }

  .timeline {
    margin-left: 20px; /* Adjust timeline for mobile */
    padding: 1rem 0;
  }

  .timeline-item .timeline-date {
    left: -80px; /* Adjust date position to fit smaller screens */
    width: 60px;
    font-size: 0.75rem; /* Make font size even smaller for the dates */
  }

  /* Skills */
  .skills-grid {
    grid-template-columns: repeat(2, 1fr); /* Show 2 cards per row on small mobile */
  }

  /* Footer */
  .footer-link {
    font-size: 0.9rem;
  }

  .footer-text {
    font-size: 0.8rem;
  }
}
